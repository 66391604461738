<template>
  <v-container fluid>
    <!-- ADHOC TIMESHEET REPORTS -->
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                AdHoc Timesheet Reports
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row>
                    <v-col sm="12" md="3" p>
                      <BaseDatePickerWithText
                        label="Start Date"
                        v-model="startDate"
                      ></BaseDatePickerWithText>
                    </v-col>
                    <v-col sm="12" md="3">
                      <BaseDatePickerWithText
                        label="End Date"
                        v-model="endDate"
                      ></BaseDatePickerWithText>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-text-field
                        v-model="searchText"
                        prepend-inner-icon="mdi-calendar-search"
                        label="Search"
                        hint="name, note, activity"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="3" class="mt-8">
                      <v-select
                        v-model="selectedFields"
                        :items="availableHeaders"
                        item-text="text"
                        item-value="value"
                        label="Select Columns"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:selection="{item, index}">
                          <v-chip
                            small
                            v-if="index < 5"
                            close
                            @click:close="selectedFields.splice(index, 1)"
                            >{{ item.text }}</v-chip
                          >
                          <span v-if="index === 5" class="grey--text caption"
                            >(+{{ selectedFields.length - 5 }} others)</span
                          >
                        </template>
                        <template v-slot:prepend>
                          <reorderSelectedFields
                            v-model="selectedFields"
                          ></reorderSelectedFields>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col sm="12" md="2">
                      <h4
                        @click="toggleFilters"
                        class="pointer primary--text"
                        >{{ filterText }}</h4
                      >
                    </v-col>
                  </v-row>
                  <v-row v-if="filters">
                    <v-col sm="12" md="3">
                      <v-select
                        v-model="status"
                        :items="availableApprovalStatus"
                        label="Status"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-select>
                    </v-col>
                    <v-col v-if="isSuperUser" sm="12" md="3">
                      <v-select
                        v-model="userDivision"
                        :items="availableUserDivisions"
                        item-text="DivisionName"
                        item-value="ID"
                        label="User Division"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-select
                        v-model="projectDivision"
                        :items="availableProjectDivisions"
                        item-text="DivisionName"
                        item-value="ID"
                        label="Project Division"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-autocomplete
                        v-model="activityCode"
                        :items="availableActivityCodes"
                        item-text="display"
                        item-value="ID"
                        label="Activity Code"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-autocomplete
                        v-model="projectDetails"
                        :items="availableProjectDetails"
                        item-text="Description"
                        item-value="ID"
                        label="Project Detail"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-select
                        v-model="projectDetailsPrefix"
                        :items="availableProjectDetailsPrefix"
                        label="Project Detail Prefix"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-select
                        v-model="departmentStrategy"
                        :items="availableDepartmentStrategies"
                        item-text="Description"
                        item-value="Code"
                        label="Department Strategy"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-autocomplete
                        v-model="project"
                        :items="projectList"
                        :search-input.sync="projectSearchText"
                        :loading="projectSearchIsLoading"
                        placeholder="Start typing to Search"
                        item-text="details"
                        item-value="ID"
                        label="Project"
                        cache-items
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:selection="{item, index}">
                          <v-chip
                            small
                            v-if="index < 5"
                            close
                            @click:close="project.splice(index, 1)"
                            >{{ item.ProjectNumber }}</v-chip
                          >
                          <span v-if="index === 5" class="grey--text caption"
                            >(+{{ project.length - 5 }} others)</span
                          >
                        </template>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              >No results for search query</v-list-item-title
                            >
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-autocomplete
                        v-model="entity"
                        :items="entityList"
                        :search-input.sync="entitySearchText"
                        :loading="entitySearchIsLoading"
                        placeholder="Start typing to Search"
                        item-text="FullName"
                        item-value="ID"
                        label="Entity"
                        cache-items
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              >No results for search query</v-list-item-title
                            >
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      v-if="isSuperUser || isProjectCreator || isSupervisor"
                      sm="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="users"
                        :items="userList"
                        :search-input.sync="userSearchText"
                        :loading="userSearchIsLoading"
                        placeholder="Start typing to Search"
                        item-text="fullName"
                        item-value="ID"
                        label="Employee"
                        cache-items
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              >No results for search query</v-list-item-title
                            >
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      v-if="isSuperUser || isProjectCreator || isSupervisor"
                      sm="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="supervisors"
                        :items="supList"
                        :search-input.sync="supSearchText"
                        :loading="supSearchIsLoading"
                        placeholder="Start typing to Search"
                        item-text="fullName"
                        item-value="ID"
                        label="Supervisor"
                        cache-items
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              >No results for search query</v-list-item-title
                            >
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <pivotOptions
                    :pivot="pivot"
                    :filteredHeaders="filteredHeaders"
                  ></pivotOptions>
                </v-card-text>
                <template>
                  <v-data-table
                    :headers="filteredHeaders"
                    :items="timesheetEntry"
                    :server-items-length="total"
                    :loading="loading"
                    :sort-by.sync="pagination.sortBy"
                    :sort-desc.sync="pagination.descending"
                    :page.sync="pagination.page"
                    :items-per-page.sync="pagination.rowsPerPage"
                    :footer-props="{
                      itemsPerPageOptions: [5, 10, 25, 50],
                    }"
                    class="pt-4"
                    dense
                  ></v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <!-- GENERATE ADHOC -->
    <v-row justify="end">
      <v-col sm="auto">
        <v-btn @click="getAdhoc" color="primary">
          <v-icon left>mdi-file-chart</v-icon>Generate AdHoc
        </v-btn>
      </v-col>
    </v-row>
    <!-- SAVED QUERIES -->
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel2" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                Saved Queries
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-form @submit.prevent>
                    <v-row>
                      <v-col sm="12" md="6">
                        <v-select
                          :items="availableSavedQueries"
                          item-text="Title"
                          item-value="ID"
                          label="Saved Queries"
                          v-model="selectedQueryID"
                          @change="selectQuery"
                          clearable
                        >
                          <!-- //note: I can't find a way to have prepend item clicks close the dropdown -->
                          <!-- <template v-slot:prepend-item>
                              <v-list-item ripple @click="selectPremadeQuery('employeeTimesheet')">
                                <v-list-item-content>
                                  <v-list-item-title>49 - Employee Timesheet</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider class="mt-2"></v-divider>
                            </template>-->
                          <template slot="item" slot-scope="data">
                            <v-row>
                              <v-col
                                v-if="data.item.ID && data.item.ID > 0"
                                sm="auto"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{on}">
                                    <v-btn color="primary" v-on="on" icon>
                                      <v-icon
                                        class="clickable"
                                        color="primary"
                                        @click="deleteConfirm(data.item)"
                                        >mdi-trash-can</v-icon
                                      >
                                    </v-btn>
                                  </template>
                                  <span>Delete Query</span>
                                </v-tooltip>
                              </v-col>
                              <v-col sm="auto" class="mt-4">{{
                                data.item.Title
                              }}</v-col>
                            </v-row>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col sm="12" md="3" offset-md="3" class="mt-4">
                        <v-btn color="primary" @click="dialog = true">
                          <v-icon left>mdi-content-save</v-icon>Save New Query
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-dialog v-model="dialog" width="450">
                      <v-card class="elevation-3">
                        <v-card-title class="primary">
                          <h3 class="hpHeading white--text"
                            >Enter New Queries</h3
                          >
                        </v-card-title>
                        <v-card-text class="pa-4">
                          <v-row>
                            <v-col sm="12">
                              <v-text-field
                                label="Query Name"
                                v-model="savedQueriesTitle"
                              ></v-text-field>
                            </v-col>
                            <v-col sm="12">
                              <v-card-actions>
                                <v-btn color="primary" @click="saveQuery"
                                  >Accept</v-btn
                                >
                                <v-btn
                                  outlined
                                  color="primary"
                                  @click="dialog = !dialog"
                                  >Cancel</v-btn
                                >
                              </v-card-actions>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                    <v-dialog persistent v-model="deleteDialog" scrollable width="350">
                      <v-card class="elevation-3">
                        <v-card-title class="primary">
                          <h3 class="hpHeading white--text">Confirm?</h3>
                        </v-card-title>
                        <v-card-text class="pa-4">
                          <v-row>
                            <v-col sm="12"
                              >Are you sure you want to delete this
                              query?</v-col
                            >
                          </v-row>
                        </v-card-text>
                          <v-card-actions>
                            <v-btn
                              color="primary"
                              @click="deleteDialog = !deleteDialog"
                              >Cancel</v-btn
                            >
                            <v-btn
                              @click="deleteQueries"
                              outlined
                              color="primary"
                              >Delete</v-btn
                            >
                          </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-form>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- SCRIPTS -->
<script>
import pivotOptions from '@components/reports/pivotOptionsObject'
import reorderSelectedFields from '@components/reports/reorderSelectedFields'
import dayjs from 'dayjs'
import download from 'downloadjs'
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import {debounce, range} from 'lodash'
import SavedQueriesTimesheet from '@classes/SavedQueriesTimesheet'
import {TimesheetReports} from '@defaults/StaticReports'
import draggable from 'vuedraggable'

export default {
  name: 'Timesheets',
  page: {
    title: 'Timesheet Reports',
  },
  components: {
    draggable,
    pivotOptions,
    reorderSelectedFields,
  },
  data: () => ({
    valid: true,
    panel: [0, 1],
    panel2: [0, 1],
    savedQueriesTitle: '',
    savedQueries: [],
    deleteQuery: {},
    dialog: false,
    deleteDialog: false,
    // reorderFieldDialog: false,
    searchText: '',
    search: '',
    startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
    selectedYearID: dayjs().get('year'),
    selectedMonthID: dayjs().get('month'),
    status: [],
    userDivision: [],
    projectDivision: [],
    users: [],
    supervisors: [],
    activityCode: [],
    project: [],
    entity: [],
    projectDetails: [],
    projectDetailsPrefix: [],
    departmentStrategy: [],
    queryName: '',
    queryIsModified: false,
    pivot: {
      includePivot: false,
      pivotFirst: true,
      pivotName: '',
      rowFields: [],
      columnFields: [],
      excludeFields: [],
      dataFields: [],
      filterFields: [],
    },
    supSearchText: '',
    supSearchCount: 0,
    supList: [],
    userSearchText: '',
    userSearchCount: 0,
    userList: [],
    entitySearchText: '',
    entitySearchCount: 0,
    entityList: [],
    projectSearchText: '',
    projectSearchCount: 0,
    projectList: [],
    filters: true,
    filterText: '-Hide Filters-',
    headers: [
      {
        text: 'Status',
        align: 'center',
        sortable: 'true',
        value: 'Status',
        visible: true,
      },
      {
        text: 'First Name',
        align: 'center',
        sortable: 'true',
        value: 'FirstName',
        visible: false,
      },
      {
        text: 'Last Name',
        align: 'center',
        sortable: 'true',
        value: 'LastName',
        visible: false,
      },
      {
        text: 'Full Name',
        align: 'center',
        sortable: 'true',
        value: 'FullName',
        visible: true,
      },
      {
        text: 'User Division',
        align: 'center',
        sortable: 'true',
        value: 'UserDivisionName',
        visible: false,
      },
      {
        text: 'Project Division',
        align: 'center',
        sortable: 'true',
        value: 'ProjectDivisionName',
        visible: false,
      },
      {
        text: 'Date Taken',
        align: 'center',
        sortable: 'true',
        value: 'Date',
        visible: true,
      },
      {
        text: 'Activity Code',
        align: 'center',
        sortable: 'true',
        value: 'ActivityCode',
        visible: true,
      },
      {
        text: 'Activity',
        align: 'center',
        sortable: 'true',
        value: 'ActivityCodeDescription',
        visible: true,
      },
      {
        text: 'Project Number',
        align: 'center',
        sortable: 'true',
        value: 'ProjectNumber',
        visible: true,
      },
      {
        text: 'Project Description',
        align: 'center',
        sortable: 'true',
        value: 'ProjectDescription',
        visible: true,
      },
      {
        text: 'Project Status',
        align: 'left',
        sortable: 'true',
        value: 'ProjectStatus',
        visible: false,
      },
      {
        text: 'SubDivision',
        align: 'center',
        sortable: 'true',
        value: 'SubDivision',
        visible: true,
      },
      {
        text: 'Note',
        align: 'center',
        sortable: 'true',
        value: 'Note',
        visible: true,
      },
      {
        text: 'Timesheet Start',
        align: 'center',
        sortable: 'true',
        value: 'StartDate',
        visible: true,
      },
      {
        text: 'Timesheet End',
        align: 'center',
        sortable: 'true',
        value: 'EndDate',
        visible: true,
      },
      {
        text: 'Supervisor',
        align: 'center',
        sortable: 'true',
        value: 'Supervisor',
        visible: true,
      },
      {
        text: 'Entity',
        align: 'center',
        sortable: 'true',
        value: 'EntityName',
        visible: true,
      },
      {
        text: 'Entity Type',
        align: 'left',
        sortable: 'true',
        value: 'EntityType',
        divisions: [4],
        visible: false,
      },
      {
        text: 'Entity Status',
        align: 'left',
        sortable: 'true',
        value: 'EntityStatus',
        visible: false,
      },
      {
        text: 'Entity Address',
        align: 'left',
        sortable: 'true',
        value: 'Address1',
        visible: false,
      },
      {
        text: 'Entity City',
        align: 'left',
        sortable: 'true',
        value: 'City',
        visible: false,
      },
      {
        text: 'Entity State',
        align: 'left',
        sortable: 'true',
        value: 'State',
        visible: false,
      },
      {
        text: 'Entity Zip',
        align: 'left',
        sortable: 'true',
        value: 'Zip',
        visible: false,
      },
      {
        text: 'Project Details',
        align: 'center',
        sortable: 'true',
        value: 'ProjectDetails',
        visible: true,
      },
      {
        text: 'Department Strategy',
        align: 'center',
        sortable: 'true',
        value: 'DepartmentStrategy',
        visible: true,
      },
      {
        text: 'Department Strategy Description',
        align: 'center',
        sortable: 'true',
        value: 'DepartmentStrategyDescription',
        visible: true,
      },
      {
        text: 'License Type',
        divisions: [5, 6],
        align: 'center',
        sortable: 'true',
        value: 'LicenseType',
        visible: false,
      },
      {
        text: 'License Number',
        divisions: [5, 6],
        align: 'center',
        sortable: 'true',
        value: 'LicenseNumber',
        visible: false,
      },
      {
        text: 'Hours',
        align: 'center',
        sortable: 'true',
        value: 'Hours',
        visible: true,
      },
      {
        text: 'Days',
        align: 'center',
        sortable: 'true',
        value: 'Days',
        visible: false,
      },
    ],
    timesheetEntry: [],
    total: 0,
    searchCount: 0,
    pagination: {
      page: 1,
      sortBy: 'LastName',
      descending: false,
      rowsPerPage: 25,
    },
    selectedFields: [],
    selectedQueryID: 0,
    staticReports: TimesheetReports,
    dataFieldModal: false,
    selectedDataField: '',
    selectedDataType: '',
  }),
  computed: {
    ...authComputed,
    loading() {
      return this.searchCount > 0
    },
    projectSearchIsLoading() {
      return this.projectSearchCount > 0
    },
    entitySearchIsLoading() {
      return this.entitySearchCount > 0
    },
    userSearchIsLoading() {
      return this.userSearchCount > 0
    },
    supSearchIsLoading() {
      return this.supSearchCount > 0
    },
    availableHeaders() {
      let list = this.headers.filter((item) => {
        if (item.divisions && item.divisions.length > 0) {
          return (
            this.isSuperUser ||
            item.divisions.includes(this.currentUser.DivisionID)
          )
        } else return true
      })
      return list
    },
    filteredHeaders: {
      get() {
        let list = this.availableHeaders.filter((h) => h.visible)
        list.sort((a, b) => {
          return (
            this.selectedFields.indexOf(a.value) -
            this.selectedFields.indexOf(b.value)
          )
        })
        return list
      },
    },
    selectedQuery() {
      return this.availableSavedQueries.find(
        (q) => q.ID == this.selectedQueryID
      )
    },
    selectedQueryParams() {
      if (this.selectedQuery) {
        return JSON.parse(this.selectedQuery.Query)
      }
    },
    searchParams() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        sortProperty: this.pagination.sortBy,
        sortDirection: this.pagination.descending ? 'desc' : 'asc',
        searchText: this.searchText,
        includeFields: this.selectedFields,
        status: this.status,
        userDivision: this.isSuperUser ? this.userDivision : [],
        projectDivision: this.isSuperUser
          ? this.projectDivision
          : this.projectDivision.length == 0
          ? [this.currentUser.DivisionID, 1002]
          : this.projectDivision,
        users: this.users,
        supervisors: this.supervisors,
        activityCode: this.activityCode,
        project: this.project,
        entity: this.entity,
        projectDetails: this.projectDetails,
        projectDetailsPrefix: this.projectDetailsPrefix,
        departmentStrategy: this.departmentStrategy,
        queryName: this.queryName,
        queryIsModified: this.queryIsModified,
        includePivot: this.pivot.includePivot,
        pivotFirst: this.pivot.pivotFirst,
        pivotName: this.pivot.pivotName,
        rowFields: this.pivot.rowFields,
        columnFields: this.pivot.columnFields,
        dataFields: this.pivot.dataFields,
        excludeFields: this.pivot.excludeFields,
        filterFields: this.pivot.filterFields,
      }
    },
    projectSearchParams() {
      if (
        !!this.projectSearchText ||
        this.projectDivision.length > 0 ||
        this.entity.length > 0 ||
        this.projectDetails.length > 0 ||
        this.projectDetailsPrefix.length > 0 ||
        this.departmentStrategy.length > 0
      ) {
        return {
          searchText: this.projectSearchText,
          division:
            this.projectDivision || this.isSuperUser
              ? this.projectDivision
              : [this.currentUser.DivisionID],
          entity: this.entity,
          projectDetails: this.projectDetails,
          projectDetailsPrefix: this.projectDetailsPrefix,
          departmentStrategy: this.departmentStrategy,
        }
      } else {
        return null
      }
    },
    entitySearchParams() {
      if (!!this.entitySearchText || this.projectDivision.length > 0) {
        return {
          searchText: this.entitySearchText,
          division:
            this.projectDivision || this.isSuperUser
              ? this.projectDivision
              : [this.currentUser.DivisionID],
        }
      } else {
        return null
      }
    },
    userSearchParams() {
      if (!!this.userSearchText || this.userDivision.length > 0) {
        return {
          searchText: this.userSearchText,
          division: this.isSuperUser
            ? this.userDivision
            : [this.currentUser.DivisionID],
        }
      } else {
        return null
      }
    },
    supSearchParams() {
      if (!!this.supSearchText || this.userDivision.length > 0) {
        return {
          searchText: this.supSearchText,
          division: this.isSuperUser
            ? this.userDivision
            : [this.currentUser.DivisionID],
          role: [3],
        }
      } else {
        return null
      }
    },
    availableApprovalStatus() {
      let list = [
        ...new Set(this.approvalStatus.map((item) => item.Description)),
      ]
      list.unshift('Unsubmitted')
      list.unshift('No Time Entered')
      return list
    },
    availableUserDivisions() {
      let list = this.division.filter((item) => {
        return (
          (this.isSuperUser && item.ID != 1002) ||
          item.ID == this.currentUser.DivisionID
        )
      })
      return list
    },
    availableProjectDivisions() {
      let list = this.division.filter((item) => {
        return (
          this.isSuperUser ||
          item.ID == this.currentUser.DivisionID ||
          item.ID == 1002
        )
      })
      return list
    },

    availableDepartmentStrategies() {
      let list = this.luDepartmentStrategy.filter((item) => {
        return (
          this.isSuperUser ||
          item.DivisionID == this.currentUser.DivisionID ||
          item.DivisionID == 1002
        )
      })
      if (
        this.isSuperUser &&
        this.userDivision &&
        this.userDivision.length > 0
      ) {
        list = list.filter(
          (item) =>
            this.userDivision.includes(item.DivisionID) ||
            item.DivisionID == 1002
        )
      }
      if (this.projectDivision && this.projectDivision.length > 0) {
        list = list.filter((item) =>
          this.projectDivision.includes(item.DivisionID)
        )
      }
      list.sort((a, b) => {
        return a.Code > b.Code ? 1 : -1
      })
      return list
    },
    availableProjectDetails() {
      let list = this.projectDetail.filter(
        (item) =>
          this.isSuperUser ||
          item.DivisionID == this.currentUser.DivisionID ||
          item.DivisionID == 1002
      )
      if (
        this.isSuperUser &&
        this.userDivision &&
        this.userDivision.length > 0
      ) {
        list = list.filter(
          (item) =>
            this.userDivision.includes(item.DivisionID) ||
            item.DivisionID == 1002
        )
      }
      if (this.projectDivision && this.projectDivision.length > 0) {
        list = list.filter((item) =>
          this.projectDivision.includes(item.DivisionID)
        )
      }
      if (this.projectDetailsPrefix && this.projectDetailsPrefix.length > 0) {
        list = list.filter((item) =>
          this.projectDetailsPrefix.includes(item.Prefix)
        )
      }
      list.sort((a, b) => {
        return a.Prefix != b.Prefix
          ? a.Prefix > b.Prefix
            ? 1
            : -1
          : a.Description > b.Description
          ? 1
          : -1
      })
      return list
    },
    availableProjectDetailsPrefix() {
      let list = this.projectDetail.filter((item) => {
        return (
          this.isSuperUser ||
          item.DivisionID == this.currentUser.DivisionID ||
          item.DivisionID == 1002
        )
      })
      if (
        this.isSuperUser &&
        this.userDivision &&
        this.userDivision.length > 0
      ) {
        list = list.filter(
          (item) =>
            this.userDivision.includes(item.DivisionID) ||
            item.DivisionID == 1002
        )
      }
      if (this.projectDivision && this.projectDivision.length > 0) {
        list = list.filter((item) =>
          this.projectDivision.includes(item.DivisionID)
        )
      }
      let distinctList = [...new Set(list.map((item) => item.Prefix))]
      distinctList.sort()
      return distinctList
    },
    availableActivityCodes() {
      let list = this.activityCodes.filter((item) => {
        return (
          this.isSuperUser ||
          item.DivisionID == this.currentUser.DivisionID ||
          item.DivisionID == 1002
        )
      })
      if (
        this.isSuperUser &&
        this.userDivision &&
        this.userDivision.length > 0
      ) {
        list = list.filter(
          (item) =>
            this.userDivision.includes(item.DivisionID) ||
            item.DivisionID == 1002
        )
      }
      if (this.projectDivision && this.projectDivision.length > 0) {
        list = list.filter((item) =>
          this.projectDivision.includes(item.DivisionID)
        )
      }
      return list.map((item) => {
        return {
          ...item,
          display: item.Code + ' ' + item.Description,
        }
      })
    },
    availableSavedQueries() {
      let list = this.savedQueries
      let list2 = []
      if (this.staticReports.length > 0) {
        this.staticReports.forEach((r) => {
          if (
            r.filterDivision.length == 0 ||
            r.filterDivision.includes(this.currentUser.DivisionID)
          ) {
            let query = r.Query
            if (r.currentUser) {
              query.users = [this.currentUser.ID]
            }
            if (r.currentUserDivision) {
              query.userDivision = [this.currentUser.DivisionID]
            }
            if (r.currentUserProjectDivision) {
              query.projectDivision = [this.currentUser.DivisionID]
            }
            if (r.currentUserAndLeaveProjectDivision) {
              query.projectDivision = [this.currentUser.DivisionID, 1002]
            }
            if (this.isSuperUser || this.isSupervisor || this.isValidator) {
              list2.push({
                Title: r.Title,
                ID: r.ID,
                Query: JSON.stringify(query),
              })
            } else if (r.currentUser) {
              list2.push({
                Title: r.Title,
                ID: r.ID,
                Query: JSON.stringify(query),
              })
            }
          }
        })
        list2.sort((a, b) => b.ID - a.ID)
      }
      return list2.concat(list)
    },
    luDepartmentStrategy: get('luDepartmentStrategy/luDepartmentStrategy'),
    approvalStatus: get('approvalStatus/approvalStatus'),
    division: get('division/division'),
    activityCodes: get('activityCodes/activityCodes'),
    projectDetail: get('projectDetail/projectDetail'),
    luDepartmentStrategy: get('luDepartmentStrategy/luDepartmentStrategy'),
  },
  created() {
    this.selectedFields = this.availableHeaders
      .filter((h) => h.visible)
      .map((h) => h.value)
    this.loadLuDepartmentStrategy()
    this.loadApprovalStatus()
    this.loadDivision()
    this.loadActivityCodes()
    this.loadProjectDetail()
    this.searchReports()
    this.getQueriesSaved()
    this.bounce(this)
  },
  watch: {
    pagination: {
      handler() {
        this.bounce(this)
      },
      deep: true,
    },
    selectedFields: {
      handler(nval, oval) {
        this.headers.forEach((item) => {
          if (nval.includes(item.value)) {
            item.visible = true
          } else {
            item.visible = false
          }
        })
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
      deep: true,
    },
    //make sure we don't have selectedFields that are not available any longer
    availableHeaders: {
      handler(nval, oval) {
        let selectedFields = this.selectedFields.filter((item) => {
          return nval.find((v) => v.value == item)
        })
        this.selectedFields = selectedFields
      },
      deep: true,
    },
    //we need to remove selections from every parameter list if they are no longer available
    availableProjectDetails: {
      handler(nval, oval) {
        let selectedFields = this.projectDetails.filter((item) => {
          return nval.find((v) => v.ID == item)
        })
        this.projectDetails = selectedFields
      },
    },
    availableProjectDetailsPrefix: {
      handler(nval, oval) {
        let selectedFields = this.projectDetailsPrefix.filter((item) => {
          return nval.find((v) => v == item)
        })
        this.projectDetailsPrefix = selectedFields
      },
    },
    availableDepartmentStrategies: {
      handler(nval, oval) {
        let selectedFields = this.departmentStrategy.filter((item) => {
          return nval.find((v) => v.Code == item)
        })
        this.departmentStrategy = selectedFields
      },
    },
    availableActivityCodes: {
      handler(nval, oval) {
        let selectedFields = this.activityCode.filter((item) => {
          return nval.find((v) => v.ID == item)
        })
        this.activityCode = selectedFields
      },
    },
    searchText: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    startDate: {
      handler() {
        this.bounce(this)
      },
    },
    endDate: {
      handler() {
        this.bounce(this)
      },
    },
    status: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    userDivision: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    projectDivision: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    users: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    supervisors: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    activityCode: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    project: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    entity: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    projectDetails: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    projectDetailsPrefix: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    departmentStrategy: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    projectSearchText: {
      handler() {
        this.projectSearchBounce(this)
      },
    },
    entitySearchText: {
      handler() {
        this.entitySearchBounce(this)
      },
    },
    userSearchText: {
      handler() {
        this.userSearchBounce(this)
      },
    },
    supSearchText: {
      handler(nval, oval) {
        this.supSearchBounce(this)
      },
    },
    currentUser: {
      handler(nval) {
        if (nval) {
          this.getQueriesSaved()
        }
      },
    },
  },
  methods: {
    loadApprovalStatus: call('approvalStatus/loadApprovalStatus'),
    loadDivision: call('division/loadDivision'),
    loadActivityCodes: call('activityCodes/loadActivityCodes'),
    loadProjectDetail: call('projectDetail/loadProjectDetail'),
    loadLuDepartmentStrategy: call(
      'luDepartmentStrategy/loadLuDepartmentStrategy'
    ),
    createSavedQueriesTimesheet: call(
      'savedQueriesTimesheet/createSavedQueriesTimesheet'
    ),
    deleteSavedQueriesTimesheet: call(
      'savedQueriesTimesheet/deleteSavedQueriesTimesheet'
    ),
    handleError: call('errors/handleError'),
    toggleFilters() {
      this.filters = !this.filters
      if (this.filters) {
        this.filterText = '-Hide Filters-'
      } else {
        this.filterText = '-Show Filters-'
      }
    },
    getQueriesSaved() {
      if (this.currentUser) {
        const url = `SavedQueriesTimesheet/User/${this.currentUser.ID}`
        this.$axios.get(url).then(
          (res) => {
            this.savedQueries = res.data
          },
          (error) => {
            this.handleError(error)
            // this.error = error
            // this.displayError = true
            console.error(error)
          }
        )
      }
    },
    deleteConfirm(item) {
      this.deleteQuery = item
      this.deleteDialog = true
    },
    deleteQueries() {
      let index = this.savedQueries.findIndex(
        (q) => q.ID == this.deleteQuery.ID
      )
      this.deleteSavedQueriesTimesheet(this.deleteQuery).then(
        (res) => {
          this.savedQueries.splice(index, 1)
        },
        (err) => {}
      )
      this.deleteDialog = false
    },
    searchReports() {
      if (this.selectedYearID > 0) {
        if (!this.pagination.sortBy) {
          this.pagination.sortBy = 'LastName'
          this.pagination.descending = false
        }
        if (Array.isArray(this.pagination.sortBy)) {
          if (this.pagination.sortBy.length > 0) {
            this.pagination.sortBy = this.pagination.sortBy[0]
          } else {
            this.pagination.sortBy = 'LastName'
          }
          this.pagination.descending = false
        }
        if (!this.searchText) {
          this.searchText = ''
        }
        this.searchCount++
        const url = `AdHocTimesheet?pageNum=${
          this.pagination.page - 1
        }&pageSize=${this.pagination.rowsPerPage}&search=${JSON.stringify(
          this.searchParams
        )}`
        this.$axios.get(url).then(
          (res) => {
            this.pagination.rowsPerPage = res.data.Page.Size
            this.pagination.page = res.data.Page.Number + 1
            this.total = res.data.Page.TotalElements
            this.timesheetEntry = res.data.Entries
            this.searchCount--
          },
          (error) => {
            this.handleError(error)
            // this.error = error
            // this.displayError = true
            console.error(error)
            this.searchCount--
          }
        )
      }
    },
    searchSupervisors() {
      if (!!this.supSearchParams) {
        const url = `User/Lookup?search=${JSON.stringify(this.supSearchParams)}`
        this.supSearchCount++
        this.$axios.get(url).then(
          (res) => {
            this.supList = res.data.map((item) => {
              return {
                ...item,
                fullName: item.FirstName + ' ' + item.LastName,
              }
            })
            this.supSearchCount--
          },
          (error) => {
            this.handleError(error)
            console.error(error)
            this.supSearchCount--
          }
        )
      } else this.supList = []
    },
    searchUsers() {
      if (!!this.userSearchParams) {
        const url = `User/Lookup?search=${JSON.stringify(
          this.userSearchParams
        )}`
        this.userSearchCount++
        this.$axios.get(url).then(
          (res) => {
            this.userList = res.data.map((item) => {
              return {
                ...item,
                fullName: item.FirstName + ' ' + item.LastName,
              }
            })
            this.userSearchCount--
          },
          (error) => {
            this.handleError(error)
            console.error(error)
            this.userSearchCount--
          }
        )
      } else this.userList = []
    },
    searchEntities() {
      if (!!this.entitySearchParams) {
        const url = `AuditEntity/Lookup?search=${JSON.stringify(
          this.entitySearchParams
        )}`
        this.entitySearchCount++
        this.$axios.get(url).then(
          (res) => {
            this.entityList = res.data.map((item) => {
              return {
                ...item,
              }
            })
            this.entitySearchCount--
          },
          (error) => {
            this.handleError(error)
            console.error(error)
            this.entitySearchCount--
          }
        )
      } else this.entityList = []
    },
    searchProjects() {
      if (!!this.projectSearchParams) {
        const url = `Project/Lookup?search=${JSON.stringify(
          this.projectSearchParams
        )}`
        this.projectSearchCount++
        this.$axios.get(url).then(
          (res) => {
            this.projectList = res.data.map((item) => {
              return {
                ...item,
                details: item.ProjectNumber + ' ' + item.Description,
              }
            })
            this.projectSearchCount--
          },
          (error) => {
            this.handleError(error)
            console.error(error)
            this.projectSearchCount--
          }
        )
      } else this.projectList = []
    },
    projectSearchFilter(item, queryText, itemText) {
      const textOne = item.Description.toLowerCase()
      const textTwo = item.ProjectNumber.toLowerCase()
      const searchText = queryText.toLowerCase()

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      )
    },
    getAdhoc(type) {
      let headers = {responseType: 'blob'}
      let url = 'AdHocTimesheet'
      if (type == 'pdf') {
        url += '/pdf'
      }
      this.$axios.post(url, this.searchParams, headers).then(
        (res) => {
          const content = res.headers['content-type']
          const dispo = res.headers['content-disposition']
          var filename = ''
          if (dispo && dispo.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            var matches = filenameRegex.exec(dispo)
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '')
            }
          }
          download(res.data, filename, content)
        },
        (error) => {
          this.handleError(error)
          // this.error = error
          // this.displayError = true
          console.error(error)
        }
      )
    },
    bounce: _.debounce((self) => {
      self.searchReports()
      // self.searchProjects()
      // self.searchEntities()
      // self.searchUsers()
      // self.searchSupervisors()
    }, 2000),
    projectSearchBounce: _.debounce((self) => {
      self.searchProjects()
    }, 500),
    entitySearchBounce: _.debounce((self) => {
      self.searchEntities()
    }, 500),
    userSearchBounce: _.debounce((self) => {
      self.searchUsers()
    }, 500),
    supSearchBounce: _.debounce((self) => {
      self.searchSupervisors()
    }, 500),

    selectQuery() {
      console.devlog('query selected')
      if (this.selectedQuery && this.selectedQuery.Query) {
        let query = JSON.parse(this.selectedQuery.Query)
        this.pagination.descending =
          query.sortDirection == 'desc' ? true : false
        this.pagination.sortBy = query.sortProperty || 'LastName'
        this.searchText = query.searchText || ''
        this.selectedFields = query.includeFields || []
        this.status = query.status || []
        this.userDivision = this.isSuperUser
          ? query.userDivision
          : [this.currentUser.DivisionID]
        this.projectDivision = query.projectDivision || []
        this.users = query.users || []
        this.supervisors = query.supervisors || []
        this.activityCode = query.activityCode || []
        this.project = query.project || []
        this.entity = query.entity || []
        this.projectDetails = query.projectDetails || []
        this.projectDetailsPrefix = query.projectDetailsPrefix || []
        this.departmentStrategy = query.departmentStrategy || []
        this.queryName = query.queryName || this.selectedQuery.Title
        this.pivot.includePivot = query.includePivot
        this.pivot.pivotFirst = query.pivotFirst
        this.pivot.pivotName = query.pivotName
        this.pivot.rowFields = query.rowFields || []
        this.pivot.columnFields = query.columnFields || []
        this.pivot.excludeFields = query.excludeFields || []
        this.pivot.dataFields = query.dataFields || []
        this.pivot.filterFields = query.filterFields || []
      } else {
        this.resetQuery()
      }
      if (this.selectedQuery && this.selectedQuery.ID <= 0) {
        //this can be used for additional processing for query presets that are hardcoded in javascript,
      }
      this.$nextTick(() => {
        this.queryIsModified = false
      })
    },
    resetQuery() {
      this.queryName = ''
      this.queryIsModified = false
    },
    saveQuery() {
      var savedQueries = new SavedQueriesTimesheet()
      savedQueries.UserId = this.currentUser.ID
      savedQueries.Title = this.savedQueriesTitle
      this.queryIsModified = false
      this.queryName = this.savedQueriesTitle
      savedQueries.Query = JSON.stringify(this.searchParams)
      this.createSavedQueriesTimesheet(savedQueries).then((res) => {
        this.savedQueries.push(res.data)
      })
      //this.user = new User()
      this.modalText = 'Save Query'
      this.dialog = !this.dialog
    },
  },
}
</script>

<!-- STYLES -->
<style lang="scss" scoped>
//@import '@design';
</style>
